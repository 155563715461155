function setAddress(addr){
    console.log('setAddress()')
  
    const attributeName = 'address';
    const attributeValue = addr;
    // const shortAddr = addr.substring(0, 6) + "..."+ addr.substring(38, 42);
    const shortAddr =  addr.substring(38, 42);
  
    document.getElementById('usrAddr').setAttribute(attributeName, attributeValue);
      new Typed('#usrAddr', { strings: [` ${shortAddr}`], typeSpeed: 50, showCursor: false });
  }

  
async function initWallets(){
    console.log('INITWALLETS')
  
    //  CHECK WEB3/METAMASK CAPABLE? ********************************************************
    if (typeof window.ethereum !== 'undefined') {
      console.log("web3 provider detected (maybe METAMASK or BRAVE WALLET)")
      metamaskBtn.style.display= 'block'
  localwalletBtn.style.display= 'block'
  magiclinkBtn.style.display= 'block'
  } else {
      console.log("NOT WEB3 BROWSER like metamask")
      metamaskBtn.style.display= 'none'
      localwalletBtn.style.display= 'block'
      reloadmetamaskBtn.style.display= 'block'
      magiclinkBtn.style.display= 'block'
  }
  
    let preferedWallet = localStorage.getItem('preferedWallet');
    if (!preferedWallet) { console.log('NO PREFERED WALLET SET') }
      if (preferedWallet) {
                const values = preferedWallet.split(',').map(item => item.trim());
                const walletChoice = values[0];
                const userAddress = values[1];
                console.log('PREFERED WALLET:', walletChoice)
                console.log('USER ADDRESS:', userAddress)
                setAddress(userAddress)
  
          
      if(walletChoice == "METAMASK"){
            console.log('METAMASK IS ACTIVE')
            initMetamaskWallet()
  
            document.getElementById('loader').style.display='none'
          // ----------
            if (window.ethereum ) {
          if (window.ethereum.isConnected()) { console.log('METAMASK IS NOT CONNECTED') }
  
                  const contractABI =ABIs[optionsList[0].TOKEN_ABI]
                  const contractAddress = optionsList[0].TOKEN_ADDRESS;
                  const chainId = optionsList[0].TOKEN_CHAINID;
                  const provider = new ethers.providers.Web3Provider(window.ethereum);
                  signer = provider.getSigner();
                  contract = new ethers.Contract(contractAddress, contractABI, signer);
                  console.log('TRYING TO GET TOKEN ID IN METAMASK (probably check chain id first)....')
                  
                  checkChainIdInMetamask()
  
                   try { tokenIdbyAddr = await contract.getIds(userAddress) }
                  catch (error) { console.warn('error:', error); return; }
                  console.log('tokenIdbyAddr', tokenIdbyAddr)
                  console.log('Smart contract inited!')
     
                  
  
                  // IF HAVE MORE THAN ONE SHOW LIST
                  console.log('STARTING CONTRACT CALL!')
                await showMusiclogCreation(tokenIdbyAddr)
                  
          }
          else { console.error("MetaMask or similar Ethereum provider not found or not connected"); }
            
        }
  
        if(walletChoice == "LOCALWALLET"){
          console.log('ARRANGE UI ACORDINGLY')
  
          // initLocalwallet()
          let encryptedjson = localStorage.getItem('jsonWallet')
          if(encryptedjson){
            console.log('THERE IS encryptedjson, UNLOCK IT TO SET IT?')
            // walletOptCreate.style.display = 'none';// boton CONNECT
            document.getElementById('walletConnect').style.display = 'none';
            document.getElementById('walletDisconnect').style.display = 'block';
            document.getElementById('navbutton').style.display = 'block';

            // HERE CONTINUE TO LOAD CONTRACT, TOKEN IDs and MUSICLOGS
      
          } 
          else{ console.log('NO encryptedjson');
        //   walletOptCreate.style.display = 'none';// boton CONNECT
          document.getElementById('walletConnect').style.display = 'none';
          document.getElementById('walletDisconnect').style.display = 'block';
          document.getElementById('navbutton').style.display = 'block';

         }
  
  
          console.log('STARTING CONTRACT FOR LOCALWALLET')
          const contractAddress = optionsList[0].TOKEN_ADDRESS;
          const contractABI =ABIs[optionsList[0].TOKEN_ABI]
          const chainId = optionsList[0].TOKEN_CHAINID;
          const tokenName = optionsList[0].TOKEN_NAME;
          const tokenTicker = optionsList[0].TOKEN_TICKER;
          let provider = await new ethers.providers.JsonRpcProvider(`${optionsList[0].API}`);
        //  let signer = await provider.getSigner();
          let  contract = new ethers.Contract(contractAddress, contractABI, provider);// WORK
          const balance = await provider.getBalance(userAddress);
          const balanceInEther = ethers.utils.formatEther(balance);
          const roundedBalance = parseFloat(balanceInEther).toFixed(4);
          console.log('ROUNDED BALANCE:',roundedBalance,balanceInEther)
          document.getElementById('balance').innerHTML=`Saldo: ${tokenTicker}: ${roundedBalance} `
          document.getElementById('solde').innerHTML=`Saldo: ${roundedBalance} ${tokenTicker}`
          // document.getElementById('balance').innerHTML=`Saldo en su direccion ETH: ${roundedBalance} `
  
          const tolerance = 0.00001; // Set your desired tolerance level
          
          if (Math.abs(roundedBalance) < tolerance) { console.log("NOT ENOUGH BALANCE, load your ETH account to pay for the TX."); }
            try { tokenIdbyAddr = await contract.getIds(userAddress) }
            catch (error) { console.error('error:', error);}
            console.log('SMART CONTRACT INITED!',tokenIdbyAddr)
            console.log('STARTING CONTRACT CALL!')
            await showMusiclogCreation(tokenIdbyAddr)
        }
  
   
  
  
  
  }
  }




 // *********************************
  // LOCALWALLET
  // *********************************
  async function selectLocalwallet(){
    console.log('SELECT LOCALWALLET:')
    
    // open modal
    closeModalId('#walletOptions')
    openModalId('#setupLocalwalletModal')

    // write localstoraga
    // localStorage.setItem('wallet', 'LOCALWALLET');
    
    // check if jsonwallet
    let jsonWallet = localStorage.getItem('jsonWallet')
    if(!jsonWallet){ 
      console.log('NO JSON WALLET:') 
      createWalletBtn.style.display= 'block'
      restoreWalletBtn.style.display= 'block'
      importWalletBtn.style.display= 'block'
    }
    else{
      console.log('YES JSON WALLET:') 
      initLocalwallet()
      unlockLocalwalletBtn.style.display= 'block'
      deleteLocalwallet.style.display= 'block'
      
    }


    // else{ useLocalWallet() }


  }



  async function unlockWallet(){
    console.log('UNLOCK LOCALWALLET:')
    // let lwallet;
    // var isModalOpen = window['#setupLocalwalletModal' + 'Modal']._isShown;

    closeModalId('#setupLocalwalletModal')

    //OPEN WALLET
      console.log('write password to open your  wallet')
      Swal.fire({
          title: 'Decrypt your wallet',
          input: 'password',
          inputAttributes: { autocapitalize: 'off' },
          showCancelButton: true,
          confirmButtonText: 'Enter',
          showLoaderOnConfirm: true,
          backdrop: true,
          preConfirm: (login) => { },
          allowOutsideClick: () => {
              const popup = Swal.getPopup()
              popup.classList.remove('swal2-show')
              setTimeout(() => { popup.classList.add('animate__animated', 'animate__headShake') })
              setTimeout(() => { popup.classList.remove('animate__animated', 'animate__headShake') }, 500)
              return false
          },
    })
    .then(async(result)   => {
    if (result.isConfirmed) {
        // walletOptCreate.style.display = 'none'
        document.getElementById('walletConnect').style.display = 'none';
        document.getElementById('walletDisconnect').style.display = 'block';
        document.getElementById('loader').style.display='inline'
        // headMessages.innerHTML = '';

        if(result.value ==''){
          console.log('IS EMPTY');
           Toast.fire({ icon: 'error', title: 'Password cannot be empty' });
          return;
        }
        // decryptWallet(result.value)
        let lwallet = await  decryptWallet(result.value)
        console.log('lwallet :',lwallet)
        // lwallet = result.value;
        // return result.value
    }  
    else if (result.isDismissed) {
      Toast.fire('', 'Wallet decrypt canceled!', "info");
       walletDisconnect()
      console.log(' DESCONECCION DE EMERGENCIA, BUG FIX')

    }

    })
      return 


  }

  async function deleteWallet(){
    console.log('DELETING JSON ENCRYPTED WALLET FROM LOCALWALLET')
    localStorage.removeItem('jsonWallet')
    localStorage.removeItem('wallet')
    localStorage.removeItem('preferedWallet')
    location.reload();


  }

  async function decryptWallet(pwd) {
    let encryptedjson = localStorage.getItem('jsonWallet')
    let ew = JSON.parse( encryptedjson)
    console.log('pub key of wallet in localstorage:', ew.address)
    let wallet;
    openModalId('#walletProgress')
    const progressCallback = (percent) => {
      walletProgressContent.innerHTML =`<div class="progress">
      <div class="progress-bar" role="progressbar" style="width:  ${percent*100}%;" aria-valuenow="${percent*100}" aria-valuemin="0" aria-valuemax="100">${ Math.round(percent * 100)}%</div>
    </div>`
      };

// let wallet ;
      try { wallet = await ethers.Wallet.fromEncryptedJson(encryptedjson, pwd, progressCallback)
      .then(async (myWallet) => {
        console.log('mywallet: ', myWallet)
        console.log('mywallet Address: ', myWallet.address)
        console.log('mywallet pk: ', myWallet.privateKey)
        localStorage.setItem('preferedWallet', `LOCALWALLET,${myWallet.address}`);

        displayDecrypt.innerHTML= myWallet.address;
        let addr;

        try { addr = await myWallet.getAddress(); }
        catch (error) { console.log('ERROR in myWallet:', error); document.getElementById('loader').style.display='none'; return ; }

        console.log('ADDRESS SUCCESS:', addr)
        document.getElementById('loader').style.display='none'
        var shortAddr = addr.substring(0, 6) + "..."+ addr.substring(38, 42);
      
    closeModalId('#walletProgress')

    
        setAddress(addr)
        // await initWallets()
        return myWallet;


        })

        return wallet 
  }
  catch (error) { console.error('ERROR FRIN wallet:', error); document.getElementById('loader').style.display='none'; closeModalId('#walletProgress'); walletDisconnect(); await Toast.fire({ icon: 'error', title: 'Password incorrect' }); console.log(' DESCONECCION DE EMERGENCIA, BUG FIX'); return; }

  }



  // LOCALWALLET
  function reloadPage() {
    location.reload(true);
  }
  
  async function startLocalwallet(){
    console.log('STARTING LOCALWALLET!!!')
    openModalId('#walletOptions')
  
  }
  

  function writepassword (f){
    closeModalId('#setupLocalwalletModal')
    //////////////////////
    // CREATE WALLET
    Swal.fire({
    title: dictionary[globalLang]["createpasswordtoencryptwallet"],
    input: 'password',
    inputAttributes: {
        autocapitalize: 'off'
    },
    showCancelButton: true,
    confirmButtonText: 'create',
    showLoaderOnConfirm: true,
  
  inputValidator: (value) => {
    if (!value) {
      return 'You need to set a password to create your wallet!'
    }
  },

    allowOutsideClick: () => {
        const popup = Swal.getPopup()
        popup.classList.remove('swal2-show')
        setTimeout(() => { popup.classList.add('animate__animated', 'animate__headShake') })
        setTimeout(() => { popup.classList.remove('animate__animated', 'animate__headShake') }, 500)
        return false
    },

    })
    .then(async(result)   => {
    if (result.isConfirmed) {
        // console.log('PASSWORD RESULT:',result.value)
        
       await  createWallet(result.value)
        return result.value
    } 
    })


}



async function createWallet(pwd){
    
// V1
  try{ wallet = ethers.Wallet.createRandom()}
  catch (error) { console.log(error.message); fixedToast.fire( 'Error',error.message, "error"); }

  console.log('address:', wallet.address)
  console.log('mnemonic:', wallet.mnemonic.phrase)

  // WRITE YOUR MNEMONIC PHRASE
    displayMnemonic.innerHTML = ` <h3><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height='40px' width='40px' fill="currentColor"> <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>Write down your mnemonic phrase to access your wallet in the future! :</h3> <div class="alert alert-warning alert-dismissible fade show"> <strong>${wallet.mnemonic.phrase}</strong> </div> `


  // CREATING ENCRYPTED LOCAL WALLET
    displayMnemonic.innerHTML += ` <div  id="loaderCW"  > <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> creating encrypted wallet ... </div> `

  // SAVE TO LOCALSTORAGE (encrypted?)
  console.log('password: ',pwd)
  const promisseJSON = wallet.encrypt( pwd);
  promisseJSON.then((jsonWallet) => {
  console.log(jsonWallet)
  console.log('WALLET ENCRYPTED')

  // loaderCW
  document.getElementById('loaderCW').innerHTML=`
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
<symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</symbol>
<symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
</symbol>
<symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
</symbol>
</svg>

  <div class="alert alert-success d-flex align-items-center" role="alert">
<svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
<div>
  Wallet encrypted and saved into localstorage
</div>
</div>
  `


  localStorage.setItem('jsonWallet', jsonWallet)
  console.log(' SAVED INTO LOCALSTORAGE')
  //   document.getElementById('walletOptCreate').style.display = 'none';// boton CONNECT
  document.getElementById('walletConnect').style.display = 'none';
  document.getElementById('walletDisconnect').style.display = 'block';

  setTimeout(() => {
    const checkbox = document.getElementById('mnemonicBackedup');
    const button = document.getElementById('closeWalletBkp');
    mnemonicBackedup.disabled = false;

    // Add an event listener to the checkbox to track its state
    checkbox.addEventListener('change', function() {
    // If the checkbox is checked, enable the button; otherwise, disable it
    button.disabled = !this.checked;
    mnemonicBackedup.disabled = true;
    console.log('close mnemonic activated')

    // NOW ADD ADDRESS TO UI AND LOCALSTORAGE
    let encryptedjson = localStorage.getItem('jsonWallet')
      const parsedJson = JSON.parse(encryptedjson);
      const hexAddress = parsedJson.address;
      const formattedAddress = ethers.utils.getAddress(hexAddress);
      console.log('formattedAddress', formattedAddress);
      localStorage.setItem('preferedWallet',`LOCALWALLET, ${formattedAddress}` )
      // localStorage.setItem('globalAddress', formattedAddress)


      setAddress(formattedAddress)

    });
    // init();
  }, 1000);
      

  });


  setTimeout(() => {
  openModalId('#walletHidden')
  }, 50);

  }



  // COPY TO CLIPBOARD
  function copy2clipboard(text) {
    console.log('COPIED!',text)
    let textLink = `<a href="${text}">${text}</a>`;
  
    if (window.clipboardData && window.clipboardData.setData) {
      Toast.fire( 'Copied to Clipboard',textLink, "success");
      return window.clipboardData.setData("Text", text);
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            Toast.fire( 'Copied to Clipboard',textLink, "success");
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
  }
  



    // WALLET BALANCE
    async function getAddressBalance(address) {
        let provider = await new ethers.providers.JsonRpcProvider(`${optionsList[0].API}`);
        const balance = await provider.getBalance(address);
        const balanceInEther = ethers.utils.formatEther(balance);
        const roundedBalance = parseFloat(balanceInEther).toFixed(4);
        return roundedBalance;
    }


// RESTORE FROM PK
async function restorePK(){
  let pk =pkTextarea.value.trim().split(/\s+/)[0];

   console.log('RESTORE WALLET: (PK)', pk)

  // check is password is set
  if(!pkPassword.value){
    pkPassword.classList.add("is-invalid");
    return
  } else {
    pkPassword.classList.remove("is-invalid");
  }


  const wallet = new ethers.Wallet(pk);
  console.log('address:', wallet.address)
  console.log('password: ',seedPassword.value)

    // HIDE INPUTS
    pkData.style.display = 'none'
    setWalletPK.style.display = 'none'
    closeWalletPK.disabled = true;
  
    // CREATE WALLET

  // seedData.style.display = 'none'
  // setWalletRestore.style.display = 'none'
  // const promisseJSON = wallet.encrypt( seedPassword.value, progressCallback);
  const promisseJSON = wallet.encrypt( seedPassword.value, showpkrestoreProgress);
  // const promisseJSON = wallet.encrypt( seedPassword.value, showProgress('displayPKRestoreProgress'));
  
  promisseJSON.then((jsonWallet) => {
          console.log(jsonWallet)
          console.log('WALLET ENCRYPTED')
          document.getElementById('displayPKRestoreProgress').innerHTML=` <svg xmlns="http://www.w3.org/2000/svg" style="display: none;"> <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16"> <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/> </symbol> <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16"> <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/> </symbol> <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16"> <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/> </symbol> </svg> <div class="alert alert-success d-flex align-items-center" role="alert"> <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg> <div> Wallet encrypted and saved into localstorage </div> </div> `
          closeWalletPK.innerHTML='close'
          localStorage.setItem('jsonWallet', jsonWallet)

          const parsedJson = JSON.parse(jsonWallet);
          const hexAddress = parsedJson.address;
          const formattedAddress = ethers.utils.getAddress(hexAddress);
          console.log('formattedAddress', formattedAddress);
          localStorage.setItem('preferedWallet',`LOCALWALLET, ${formattedAddress}` )
          setAddress(formattedAddress)

          console.log(' SAVED INTO LOCALSTORAGE')
          setTimeout(async()  => {
            closeWalletPK.disabled = false;
            setWalletPK.disabled= true;

            // clear fields 
            pkTextarea.value = ''
            pkPassword.value = '';


            await initLocalwallet()
          }, 1000);
      });


}

// RESTORE
async function restoreSeed(seed){
  console.log('RESTORE WALLET: (SEED)',seedTextarea.value.trimStart().trimEnd())

  // check is password is set
  if(!seedPassword.value){
    seedPassword.classList.add("is-invalid");
    return
  } else {
    seedPassword.classList.remove("is-invalid");
  }


// ethers.Wallet.fromMnemonic( mnemonic [ , path , [ wordlist ] ] ) ⇒ Wallet
// If path is not specified, the Ethereum default path is used (i.e. m/44'/60'/0'/0/0).
  const wallet = ethers.Wallet.fromMnemonic(seedTextarea.value.trimStart().trimEnd());
  console.log('address:', wallet.address)
  console.log('password: ',seedPassword.value)

  // HIDE INPUTS
  seedData.style.display = 'none'
  setWalletRestore.style.display = 'none'
  closeWalletRestore.disabled = true;

  // CREATE WALLET
  const promisseJSON = wallet.encrypt( seedPassword.value, progressCallback);
  promisseJSON.then((jsonWallet) => {
  console.log(jsonWallet)
  console.log('WALLET ENCRYPTED')
  document.getElementById('displayRestoreProgress').innerHTML=` <svg xmlns="http://www.w3.org/2000/svg" style="display: none;"> <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16"> <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/> </symbol> <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16"> <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/> </symbol> <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16"> <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/> </symbol> </svg> <div class="alert alert-success d-flex align-items-center" role="alert"> <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg> <div> Wallet encrypted and saved into localstorage </div> </div> `
  closeWalletRestore.innerHTML='close'
  localStorage.setItem('jsonWallet', jsonWallet)
  const parsedJson = JSON.parse(jsonWallet);
  const hexAddress = parsedJson.address;
  const formattedAddress = ethers.utils.getAddress(hexAddress);
  console.log('formattedAddress', formattedAddress);
  localStorage.setItem('preferedWallet',`LOCALWALLET, ${formattedAddress}` )
  setAddress(formattedAddress)

  console.log(' SAVED INTO LOCALSTORAGE')
  setTimeout(async()  => {
  closeWalletRestore.disabled = false;


       // clear fields 
       seedTextarea.value = ''
       seedPassword.value = '';


       await initLocalwallet()
  await initLocalwallet()
  }, 1000);
      });
}

async function restoreWallet(){
  console.log('restoreWallet()')
  closeModalId('#setupLocalwalletModal')
  openModalId('#walletRestore')

seedTextarea.addEventListener('input', function (evt) {
console.log(this.value);

    let cntwrds = countWords(this.value)
    if(cntwrds >= 12 ){
      setWalletRestore.disabled = false;
    } else{
      setWalletRestore.disabled = true;
    }

    });

}


async function importPK(){
  console.log('importPK: (IN PROGRESS...Ill bix this ASAP...)')
  closeModalId('#setupLocalwalletModal')
  openModalId('#walletImportPK')

  pkTextarea.addEventListener('input', function (evt) {
        let pk =this.value.trim().split(/\s+/)[0];
        const isValid = isValidPrivateKey(pk);
        if (isValid) { 
          console.log('VALID private key!');
          setWalletPK.disabled = false;
         } 
        else { 
          console.log('INVALID private key.'); 
          setWalletPK.disabled = true;
    }
    });

}

function isValidPrivateKey(privateKey) {
console.log('chicking this pk', privateKey)
  if (!ethers.utils.isHexString(privateKey, 32) && privateKey.length !== 64) {
    console.log('FALSE : Private key should be a 32-byte hexadecimal string');
      return false; // Private key should be a 32-byte hexadecimal string
  }
  try {
  console.log('TRUE');
  return true; // Private key is valid and corresponds to an address
} catch (error) {
    console.log('FALSE');
      return false; // Private key is invalid
  }
}


  
function countWords(str) {
  let lngth =str.trim().split(/\s+/).length;
  console.log(lngth)
  return lngth;
}

  // SHOW DECRYPT PROGRESS 
  const progressCallback = (percent) => {
    displayRestoreProgress.innerHTML =`<div class="progress">
    <div class="progress-bar" role="progressbar" style="width:  ${percent*100}%;" aria-valuenow="${percent*100}" aria-valuemin="0" aria-valuemax="100">${ Math.round(percent * 100)}%</div>
  </div>`
    }
    // const showProgress = (percent,displayPKRestoreProgress) => {
      const showpkrestoreProgress = (percent) => {
      // let thisprogress = document.getElementById(`${displayPKRestoreProgress}`)
      displayPKRestoreProgress.innerHTML =`<div class="progress">
      <div class="progress-bar" role="progressbar" style="width:  ${percent*100}%;" aria-valuenow="${percent*100}" aria-valuemin="0" aria-valuemax="100">${ Math.round(percent * 100)}%</div>
    </div>`
  }
    


//  // *********************************
//   // initWallet
//   // *********************************

  function copyAddress(){
    console.log('copyAddress')
    var element = document.getElementById('usrAddr');

    // Get the value of the "customAttribute" attribute
    var attributeValue = element.getAttribute('address');
    
    // Log the value to the console
    console.log(attributeValue);

    if (window.clipboardData && window.clipboardData.setData) {
      Toast.fire( 'Copied to Clipboard',attributeValue, "success");
      return window.clipboardData.setData("Address", attributeValue);
    }

    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = attributeValue;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            Toast.fire( 'Copied to Clipboard',attributeValue, "success");
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        }
        finally {
            document.body.removeChild(textarea);
        }
    }


    
  }








 // *********************************
  // initWallet
  // *********************************
  async function initLocalwallet(){
    console.log('initLocalwallet() ')
    let encryptedjson = localStorage.getItem('jsonWallet')
    if(encryptedjson){
      console.log('THERE IS encryptedjson, UNLOCK IT TO SET IT?')
      //   walletOptCreate.style.display = 'none';// boton CONNECT
    //   document.getElementById('walletConnect').style.display = 'none';
    //   document.getElementById('walletDisconnect').style.display = 'block';

    } 
    else{ console.log('NO encryptedjson') }
  }

    // *********************************
  // METAMASK
  // *********************************
    async function initMetamaskWallet(){
    console.log('initMetamaskWallet()')
    try {
      if (window.ethereum) {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
           let userAddress = accounts[0];
          console.log(`User's address is ${userAddress}`);
          localStorage.setItem('preferedWallet', `METAMASK, ${userAddress}`)
        //   document.getElementById('walletOptCreate').style.display = 'none';// boton CONNECT
        document.getElementById('walletConnect').style.display = 'none';
          document.getElementById('walletDisconnect').style.display = 'block';
          setAddress(userAddress)

      } 
      else { console.error("MetaMask or similar Ethereum provider not found"); }
  } 
  catch (error) { console.error(error); }

  }




 // *********************************
  // CONNECT / DISCONNECT
  // *********************************
  async function connect(){
    console.log('connect())')

    await initWallets()

        
    let encryptedjson = localStorage.getItem('jsonWallet')
    if(encryptedjson){
      console.log('CONFIRMED: THERE IS encryptedjson')
    
    }
    else{
      console.error('THERE IS NOT encryptedjson')
      

    }

    openModalId('#walletOptions')
    let walletChoice = localStorage.getItem('wallet')
    if(!walletChoice){ 
      console.log('NO PREFERED WALLET:')
     } 
    else{
      if(walletChoice == "LOCALWALLET"){
        // openModalId('#walletOptions')
      console.log('PREFERED WALLET= LOCALWALLET')
      initLocalwallet()
      // selectLocalwallet()

      }
      if(walletChoice == "METAMASK"){
      console.log('PREFERED WALLET= METAMASK')
      // initSmartcontract()
      initMetamaskWallet()
      // aqui continuar?
      console.log('aqui continuar con metamasK?')
      

      }
    }
  }
    // DISCONNECT
  async function walletDisconnect(){
    console.log('walletDisconnect')

    // CHANGE BUTTON
    // walletOptCreate.style.display = 'block';// boton CONNECT
    document.getElementById('walletConnect').style.display = 'block';
    document.getElementById('walletDisconnect').style.display = 'none';
    // document.getElementById('navbutton').style.display = 'none';

    // DELETE WALLET CHOICE FROM LOCALSTORAGE
    // localStorage.removeItem('wallet');
    localStorage.removeItem('globalAddress');
    localStorage.removeItem('preferedWallet');
    

    // hide main
    usrAddr.innerHTML=''
    // headMessages.innerHTML=''    

      // UNSET GLOBAL ADDRESS
      userAddres = '';

      // IF CREATION IS VISIBLE, HIDE IT
    //   hideMusiclogCreation()

  }



/*********************************************************************************************
.) LOG stuff
**********************************************************************************************/
// async function walletConnect() {
//     console.log('wallet connect!');
//     try{
//       const user = await Moralis.authenticate({ provider: "walletconnect" });
//       const web3 = await Moralis.enableWeb3({ provider:   "walletconnect" });
  
//     } catch (error) {
//       console.log('error:', error)
//     }
  
//     let userAddress = user.get('ethAddress');
  
//     var x = userAddress;
//     var shortAddr = x.substring(0, 8) + "...";
//     document.getElementById("logg").innerHTML = "<p class='loged btn-grad-inv'>" + shortAddr + ` <i class='logout ' aria-hidden='true' onclick='event.stopPropagation();logOut()'><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-logout" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
//     <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//     <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
//     <path d="M7 12h14l-3 -3m0 6l3 -3"></path>
//   </svg></i> </p>`;
  
//   }
  
async function showMusiclogCreation(tokenIdbyAddr){
  console.log(' showMusiclogCreation()')
 
  // +++++++++++++++++++++++++++++++++++++++++
  // CREATE CONTRACT OBJECT
 //  new ethers.Contract( address , abi , signerOrProvider )
 const contractABI =ABIs[optionsList[0].TOKEN_ABI]
 const contractAddress = optionsList[0].TOKEN_ADDRESS;
  // CREATE PROVIDER 
  try { provider = new ethers.providers.JsonRpcProvider(`${optionsList[0].API}`);}
  catch (error) { console.log(error.message); fixedToast.fire( 'Error',error.message, "error"); }
  console.log('provider:', provider)
  
  // 2- CREATE CONTRACT
  let contract;
  try{ contract = new ethers.Contract(contractAddress, contractABI, provider); }
  catch (error) { console.error("An error occurred:", error); Toast.fire( 'An error occurred','', "error"); closeWalletProgress.disabled= false; return }
  
  // +++++++++++++++++++++++++++++++++++++++++
 
 // CALL IT
   if(tokenIdbyAddr.length > 1){
     console.log('MORE THAN ONE MUSICLOG...')
     console.log(`You already have a ${tokenIdbyAddr.length} musicblogs`)
     lastMusicblog = parseInt(tokenIdbyAddr[tokenIdbyAddr.length -1],10 );
     console.log(`Your last one is ${lastMusicblog}`)
     // =============================FOREACH
     tokenIdbyAddr.forEach(async function(token) {
         let parsedToken = parseInt(token, 10);
         console.log('parsedToken:',parsedToken)
         // document.getElementById('musiclogList').innerHTML +=  `<li>${await contract.getTableName(parsedToken)}</li>`
         let tableName = await contract.getTableName(parsedToken);
         let listItem = document.createElement('li');
 
         listItem.setAttribute('data-musiclogId', tableName);
 
         listItem.textContent = tableName;
         document.getElementById('musiclogList').appendChild(listItem);
     });
     // =============================FOREACH
     document.querySelector('header').style.display = 'none';
 
   }
   else if(tokenIdbyAddr.length == 1){
     console.log('JUST ONE MUSICLOG...')
 
     // console.log(`You already have a ${tokenIdbyAddr.length} musicblogs`)
     lastMusicblog = parseInt(tokenIdbyAddr[tokenIdbyAddr.length -1],10 );
     console.log(`Your musiclog is number: ${lastMusicblog}`)
 
     createMusiclog.style.display = 'inline'
     await TweenMax.staggerTo('#splashImage', 0.5, { opacity: 0, x: -20, ease: Power2.easeIn }, 0.1);
     
     try {tableName = await contract.getTableName(lastMusicblog) }
     catch (error) { console.log('error:', error); return; }
     console.log('tableName: ',tableName)
 
     document.querySelector('header').style.display = 'none';
 
     gallery.style.display='none';
    await loadMusiclog(tableName)
 
 
 
   }
   else{// IF HAVE MORE THAN ONE SHOW LIST
     console.log('NO MUSICLOG YET...')
     console.log('go on, create your first musicblog!')
     createMusiclog.style.display = 'inline'
     await TweenMax.staggerTo('#splashImage', 0.5, { opacity: 0, x: -20, ease: Power2.easeIn }, 0.1);
   }
 }